import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';

import { BaseControlDirective } from '../base-control.directive';

export type InputType = 'text' | 'email' | 'password';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['../controls-styles.scss', './input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent, ReactiveFormsModule, NgClass],
})
export class InputComponent extends BaseControlDirective {
  @Input() public type: InputType = 'text';

  @Input() public name: InputType = 'text';

  @Input() public maxlength!: number;

  public hidePass = true;

  public emptyControl = true;

  public inputInFocus = false;

  constructor(
    protected override ngControl: NgControl,
    protected override cdr: ChangeDetectorRef,
    protected override destroyRef: DestroyRef
  ) {
    super(ngControl, cdr, destroyRef);

    this.formControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(controlValue => {
        this.emptyControl = !controlValue;
        this.cdr.markForCheck();
      });
  }

  public hideShowPass(event: Event): void {
    event.stopPropagation();
    this.hidePass = !this.hidePass;
  }

  public setInputFocusStatus(status: boolean) {
    this.inputInFocus = status;
    this.cdr.markForCheck();
  }
}
