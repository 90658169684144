@if (!(emptyControl && !inputInFocus)) {
  <span class="label">
    {{ label }}
    @if (markedAsRequired) {
      *
    }
  </span>
}

<div
  class="input-container"
  [ngClass]="{
    'input-container_focused': inputInFocus,
    'input-container_error': error,
  }">
  <input
    #input
    class="input t-heading-6"
    [maxlength]="maxlength"
    [type]="type === 'password' ? (hidePass ? type : 'text') : type"
    [name]="name"
    [placeholder]="emptyControl && !inputInFocus ? label : ''"
    [formControl]="formControl"
    (focus)="setInputFocusStatus(true)"
    (blur)="setInputFocusStatus(false)"
    (blur)="blur($event)" />

  @if (type === 'password') {
    <svg-icon
      class="eye-icon"
      [class.view-pass]="!hidePass"
      [key]="hidePass ? 'eye-on' : 'eye-off'"
      (click)="hideShowPass($event)"
      size="xl"></svg-icon>
  }
</div>

<span class="error">{{ error }}</span>
