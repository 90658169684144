import { IRole } from '../interfaces/auth/role.interface';

export const userRoles: IRole[] = [
  { value: 'STUDENT', label: $localize`Студент` },
  { value: 'INTERN', label: $localize`Интерн` },
  { value: 'DOCTOR', label: $localize`Врач` },
  { value: 'TEACHER', label: $localize`Преподаватель` },
  { value: 'BLOGGER', label: $localize`Блогер` },
  { value: 'INVESTOR', label: $localize`Инвестор` },
  { value: 'JOURNALIST', label: $localize`Журналист` },
  { value: 'OTHER', label: $localize`Другая` },
];
