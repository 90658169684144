/*eslint-disable @angular-eslint/no-output-on-prefix*/
import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';

import { errorsLocalization } from './errors-localization';

@Directive()
export class BaseControlDirective
  implements ControlValueAccessor, OnInit, DoCheck
{
  @Input() public label = '';

  @Input() public placeholder = '';

  @Input() public errorsMap: { [key: string]: string } = {};

  @Input() public markedAsRequired: boolean = false;

  @Output() public onBlur = new EventEmitter<Event>();

  public formControl = new UntypedFormControl(null);

  public error = '';

  public errorState = false;

  public touched = false;

  public errorsLocalization = errorsLocalization;

  constructor(
    protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    protected destroyRef: DestroyRef
  ) {
    this.ngControl.valueAccessor = this;
  }

  public ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        this.onChange(value);
      });
  }

  public ngDoCheck(): void {
    this.checkTouchedStatus();
    if (this.touched) {
      this.error = this.getErrorMessage(
        this.ngControl.control?.errors,
        this.ngControl.control?.status || ''
      );
      this.errorState = this.ngControl.control?.invalid || false;
      this.cdr.markForCheck();
    } else {
      this.errorState = false;
      this.error = '';
    }
  }

  public onChange = (_event: any) => {};

  public onTouched = (_event: any) => {};

  public writeValue(obj: any): void {
    this.formControl.setValue(obj);
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  public blur(event: Event): void {
    this.onTouched(event);
    this.onBlur.emit(event);
  }

  private checkTouchedStatus(): void {
    this.touched = Boolean(this.ngControl.control?.touched);
    this.cdr.markForCheck();
  }

  private getErrorMessage(
    formsErrors: ValidationErrors | undefined | null,
    status: string
  ): string {
    const errorKeys = Object.keys(formsErrors || {});
    const key = errorKeys.length && errorKeys[0];
    if (!key) {
      return '';
    }
    return status === 'INVALID'
      ? this.errorsLocalization[key][this.errorsMap?.[key]] || ''
      : '';
  }
}
